import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import { AuthProvider } from "./context/AuthContext";
import DeepLink from "./components/DeepLink";
// import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    // <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <Layout />
          <DeepLink />
        </AuthProvider>
      </BrowserRouter>
    // </HelmetProvider>
  );
}

export default App;
