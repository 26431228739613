import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  // Chip,
  // Card,
  Checkbox,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import axios from "axios";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import "react-quill/dist/quill.snow.css";
import { renderFileIcon } from "../../helpers/renderFileIcon";
import { ReactComponent as Description } from "../../assets/images/Text.svg";
import CustomLabelPicker from "../../components/CustomeLablePicker";
import moment from "moment";

const PDFTodo = () => {
  const location = useLocation();
  const [note, setNote] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  // const [selectedTags, setSelectedTags] = useState([]);
  const [existingAttachments, setexistingsAttachments] = useState([]);

  const imageList = existingAttachments?.filter((att) => att.type === "image");

  useEffect(() => {
    const noteId = location.pathname.split("/")[3];
    fetchNoteDetail(noteId);
  }, [location]);

  const fetchNoteDetail = async (noteId) => {
    try {
      const response = await axios.get(`/notes/public/${noteId}`);
      if (response?.data?.isLocked || response?.data?.isDeleted) {
        setNote({ title: response?.data?.title });
      }
      // document.title=response?.data?.note?.title;
      const fetchedNote = response?.data?.note;
      setNote({ ...fetchedNote, details: Parser(fetchedNote?.details) });
      setSelectedColor(fetchedNote.color);
      setexistingsAttachments(fetchedNote.attachments);
      // setSelectedTags(fetchedNote.tags);
    } catch (error) {
      console.error("Failed to fetch note:", error);
    }
  };

  const getLuminance = (color) => {
    if (!color || color.length === 0) return null;
    let r, g, b;
    if (color.startsWith("#")) {
      const hex = color.substring(1);
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else {
      const rgb = color.match(/\d+/g);
      r = parseInt(rgb[0], 10);
      g = parseInt(rgb[1], 10);
      b = parseInt(rgb[2], 10);
    }

    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const setTextColor = (color) => {
    if (!color || color.length === 0) return null;
    const isDarkColor = getLuminance(color) < 128;
    return isDarkColor ? "#fff" : "#000";
  };

  const setTodoDescTextColor = (color) => {
    if (!color || color.length === 0) {
      return null; // or handle it appropriately
    }
    const isDarkColor = getLuminance(color) < 128;
    const textColor = isDarkColor ? "#DCDCDC" : "#575A59";
    return textColor;
  };

  // const setChipColor = (color) => {
  //   const isDarkColor = getLuminance(color) < 128;
  //   return isDarkColor ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)";
  // };

  const truncateFileName = (fileName) => {
    const maxLength = 15;
    return fileName.length > maxLength ? fileName.substring(0, maxLength) + "..." : fileName;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: selectedColor,
        padding: 0,
        color: setTextColor(selectedColor),
        overflowY: "auto",
        position: "relative",
        transition: "background-color 0.3s ease",
        "@media print": {
          height: "auto", // Allow content to expand fully
          overflow: "visible", // Remove scrollbars
        },
      }}
    >
      <Box
        sx={{
          marginTop: 2,
          width: "90%",
          maxWidth: 600,
          textAlign: "center",
        }}
      >
        <TextField
          variant="standard"
          multiline
          fullWidth
          placeholder="Title"
          value={note ? note.title : ""}
          InputProps={{
            style: {
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "25px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            },
            disableUnderline: true,
            readOnly: true,
          }}
          sx={{
            marginBottom: 2,
            minWidth: "100%",
            "& .MuiInputBase-input": {
              textAlign: "center",
              textTransform: "capitalize",
              color: setTextColor(selectedColor),
            },
          }}
        />
        <div
          style={{
            height: "auto", // Allow full height for printing
            overflow: "visible", // Ensure all content is visible
          }}
        >
          <div style={{ margin: 4 }}>
            {note?.todoItems?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 2,
                  borderBottom: "1px solid",
                  borderColor: setTextColor(selectedColor),
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  {/* Title with Checkbox */}
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Checkbox
                      checked={item.isCompleted}
                      sx={{ color: setTextColor(selectedColor), padding: 0 }}
                      disabled // Disable checkbox to prevent interaction
                    />
                    <TextField
                      value={item.title}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true, // Make the text field non-editable
                        style: {
                          textDecoration: item.isCompleted ? "line-through" : "none",
                          color: setTextColor(selectedColor),
                        },
                      }}
                      sx={{
                        lineHeight: "0px",
                        "& .MuiInputBase-input": {
                          padding: 0,
                        },
                      }}
                    />
                  </Box>

                  {/* Description */}
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Description style={{ height: "28px", width: "28px" }} />
                    <TextField
                      value={item.description || "No description provided"}
                      fullWidth
                      variant="standard"
                      multiline
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true, // Make the description field non-editable
                        style: {
                          color: setTodoDescTextColor(selectedColor),
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: 0,
                          lineHeight: "1.1",
                        },
                      }}
                    />
                  </Box>

                  {/* Date/Time */}
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <div style={{ pointerEvents: "none" }}>
                      <CustomLabelPicker
                        index={index}
                        selectedDate={item?.date_time}
                        disabled // Disable CustomLabelPicker if needed
                      />
                    </div>
                    <TextField
                      disabled
                      value={
                        item?.date_time
                          ? moment(item?.date_time).format("DD-MM-YYYY hh:mm A")
                          : "No date/time provided"
                      }
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true, // Ensure the field is non-editable
                        // style: {
                        //   color: "black",
                        // },
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: 0,
                          lineHeight: "1.1",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          padding: 0,
                          lineHeight: "1.1",
                          WebkitTextFillColor: setTodoDescTextColor(selectedColor),
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
          {/* Images Section */}
          {imageList?.length > 0 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
                marginTop: 2,
              }}
            >
              {imageList.map((attachment, index) => (
                <img
                  key={attachment?._id}
                  src={attachment?.url}
                  alt={attachment?._id}
                  style={{
                    width: "100%",
                    height: "150px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              ))}
            </Box>
          )}

          {/* Audio Section */}
          {existingAttachments?.some((att) => att.type === "audio") && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 2 }}>
              {existingAttachments
                ?.filter((att) => att.type === "audio")
                .map((attachment, index) => (
                  <Box key={attachment._id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <AudiotrackIcon />
                    <audio controls style={{ width: "100%" }}>
                      <source src={attachment.url} type={attachment.mimeType} />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                ))}
            </Box>
          )}

          {/* Files Section */}
          {existingAttachments?.some(
            (att) => !att.type.includes("image") && !att.type.includes("audio")
          ) && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
                marginTop: 2,
              }}
            >
              {existingAttachments
                .filter((att) => !att.type.includes("image") && !att.type.includes("audio"))
                .map((attachment, index) => (
                  <Box
                    key={attachment._id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "0 10px",
                      backgroundColor: "#f9f9f9",
                      textAlign: "center",
                    }}
                  >
                    {renderFileIcon(attachment.fileName.split(".").pop())}
                    <Box
                      sx={{
                        ml: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        flexGrow: 1,
                      }}
                    >
                      {truncateFileName(attachment.fileName)}
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default PDFTodo;
