import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  //  Chip,
  // Card,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import axios from "axios";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { renderFileIcon } from "../../helpers/renderFileIcon";

const PDFNote = () => {
  const location = useLocation();
  const [note, setNote] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [existingAttachments, setexistingsAttachments] = useState([]);

  const imageList = existingAttachments?.filter((att) => att.type === "image");

  useEffect(() => {
    const noteId = location.pathname.split("/")[3];
    fetchNoteDetail(noteId);
  }, [location]);

  const fetchNoteDetail = async (noteId) => {
    try {
      const response = await axios.get(`/notes/public/${noteId}`);
      if (response?.data?.isLocked || response?.data?.isDeleted) {
        setNote({ title: response?.data?.title });
      }
      const fetchedNote = response?.data?.note;
      setNote({ ...fetchedNote, details: Parser(fetchedNote?.details) });
      setSelectedColor(fetchedNote.color);
      setexistingsAttachments(fetchedNote.attachments);
      // setSelectedTags(fetchedNote.tags);
    } catch (error) {
      console.error("Failed to fetch note:", error);
    }
  };

  const getLuminance = (color) => {
    if (!color || color.length === 0) return null;
    let r, g, b;
    if (color.startsWith("#")) {
      const hex = color.substring(1);
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else {
      const rgb = color.match(/\d+/g);
      r = parseInt(rgb[0], 10);
      g = parseInt(rgb[1], 10);
      b = parseInt(rgb[2], 10);
    }

    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const setTextColor = (color) => {
    if (!color || color.length === 0) return null;
    const isDarkColor = getLuminance(color) < 128;
    return isDarkColor ? "#fff" : "#000";
  };

  const truncateFileName = (fileName) => {
    const maxLength = 15;
    return fileName.length > maxLength ? fileName.substring(0, maxLength) + "..." : fileName;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "16px",
        backgroundColor: selectedColor,
        color: setTextColor(selectedColor),
      }}
    >
      <TextField
        variant="standard"
        multiline
        fullWidth
        placeholder="Title"
        value={note ? note.title : ""}
        InputProps={{
          style: {
            fontWeight: "bold",
            fontSize: "25px",
          },
          disableUnderline: true,
          readOnly: true,
        }}
        sx={{
          marginBottom: 2,
          textAlign: "center",
          textTransform: "capitalize",
          color: setTextColor(selectedColor),
          backgroundColor: selectedColor,
          "& .MuiInputBase-input": {
            textAlign: "center",
          },
        }}
      />

      <Box
        sx={{
          marginTop: 2,
          width: "100%",
          maxWidth: 600,
          textAlign: "center",
        }}
      >
        <ReactQuill
          value={note?.details}
          readOnly={true}
          theme="bubble"
          className="public-note"
          style={{
            color: setTextColor(selectedColor),
            // height: "auto !important",
            // maxHeight: "auto !important",
          }}
        />

        {/* Images Section */}
        {imageList?.length > 0 && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
              marginTop: 2,
            }}
          >
            {imageList.map((attachment, index) => (
              <img
                key={attachment?._id}
                src={attachment?.url}
                alt={attachment?._id}
                style={{
                  width: "100%",
                  height: "150px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
            ))}
          </Box>
        )}

        {/* Audio Section */}
        {existingAttachments?.some((att) => att.type === "audio") && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 2 }}>
            {existingAttachments
              ?.filter((att) => att.type === "audio")
              .map((attachment, index) => (
                <Box key={attachment._id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <AudiotrackIcon />
                  <audio controls style={{ width: "100%" }}>
                    <source src={attachment.url} type={attachment.mimeType} />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              ))}
          </Box>
        )}

        {/* Files Section */}
        {existingAttachments?.some(
          (att) => !att.type.includes("image") && !att.type.includes("audio")
        ) && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
              marginTop: 2,
            }}
          >
            {existingAttachments
              .filter((att) => !att.type.includes("image") && !att.type.includes("audio"))
              .map((attachment, index) => (
                <Box
                  key={attachment._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "0 10px",
                    backgroundColor: "#f9f9f9",
                    textAlign: "center",
                  }}
                >
                  {renderFileIcon(attachment.fileName.split(".").pop())}
                  <Box
                    sx={{
                      ml: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flexGrow: 1,
                    }}
                  >
                    {truncateFileName(attachment.fileName)}
                  </Box>
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PDFNote;
