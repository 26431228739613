import { Button, Box, Typography } from "@mui/material";
import React, { useState } from "react";

function DeepLink() {
  const [isSkipped, setIsSkipped] = useState(false);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
    return "unknown";
  }

  const os = getMobileOperatingSystem();

  const openApp = () => {
    if (os === "android") {
      window.location.replace("https://play.google.com/store/apps/details?id=com.nexuslink.mynote");
    }
    if (os === "ios") {
      window.location.replace("https://apps.apple.com/in/app/my-enotes/id1572424954");
    }
  };

  return (
    !isSkipped &&
    (os === "android" || os === "ios") && (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 999,
          backgroundColor: "#f5f5f5",
          width: "100%",
          p: 2,
        }}
      >
        <Box sx={{ backgroundColor: "#ddd", p: 2, borderRadius: 1 }}>
          <Typography variant="h6">Better on the App</Typography>
          <Typography variant="body2">
            Open this link in the app for a better experience.
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button className="cancel-btn btn" onClick={() => setIsSkipped(true)}>
              Not Now
            </Button>
            <Button className="logout-btn btn" onClick={openApp}>
              Switch to App
            </Button>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default DeepLink;
