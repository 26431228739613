import { useLocation } from "react-router-dom";
import Routers from "../routes/router";
// import Footer from "./Footer";
import { Box } from "@mui/material";

const Layout = () => {
  const blankLayout = ["/login", "/register", "/forgot-password"];
  const location = useLocation();

  return (
    <>
      {blankLayout.includes(location.pathname) ? (
        <>
          {/* <div className="loginbackground_img"></div> */}
          <Routers />
        </>
      ) : (
        <>
          {/* <div className="background_img"></div> */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              padding: 0,
              margin: 0,
              // overflow: "hidden",
            }}
          >
            <Routers />
          </Box>
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default Layout;
